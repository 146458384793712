*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'JosefinSans-Regular';
    src: local('JosefinSans-Regular'), url(../../assets/fonts/JosefinSans-Regular.ttf) format('truetype');
}

:root{
    --bg-color:#eeeeee;
    --mango:#fda435;
}

.container{
    height: 100vh;
    width:100vw;
    background-color: var(--bg-color);
    position: relative;
    overflow: hidden;
}

.Top-Border {
    width: 100%;
    height: 4px;
    background-color: var(--mango);
    position: relative;
    overflow: hidden;
  }

.Logo {
    overflow: hidden;
    width: 48px;
    height: 14px;
    object-fit: contain;
    float: right;
    margin-right: 7px;
    margin-top: 6px;
}

.Heading {
    overflow: hidden;
    width: 534px;
    height: 53px;
    object-fit: contain;
}



.mainContainer{
    /* overflow: hidden; */
    position: relative;
    margin:auto;
    display: flex;
    width: 80%;
    height: 85%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.topContainer{
    height:10%;
    /* overflow: hidden; */
}

.handleContainer{
    /* overflow: hidden; */
    width: 100%;
    /* background-color: blue; */
    height: 90%;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;

}

@media screen and (min-width: 700px) {
    .handleContainerSafari{
        /* /* transform: translateX(-8vw); */
        justify-content: center;
    }

}

@media screen and (min-width: 900px) {

    .leftHandleSafari{
        overflow: hidden;
        width: auto;
        height: 100vh!important;
        object-fit: contain;
        box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.15);
        /* background-color: #ee9a32; */

    }

    .rightHandleSafari{
            overflow: hidden;
            width: auto;
            height: 100vh!important;
            object-fit: contain;
            box-shadow: 0 6px 0 0 rgba(0, 0, 0, 0.15);
            /* background-color: var(--mango); */

    }

}


.leftHandle{
        overflow: hidden;
        width: auto;
        height: 100%;
        object-fit: contain;
        box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.15);
        /* background-color: #ee9a32; */

}

.rightHandle{
        overflow: hidden;
        width: auto;
        height: 100%;
        object-fit: contain;
        box-shadow: 0 6px 0 0 rgba(0, 0, 0, 0.15);
        /* background-color: var(--mango); */

}

.topSpace{
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 15%;
}

.custom{
    height: 80%;
    overflow: hidden;
}

.custom2{
    height: 58%;
    overflow: hidden;
}

.leftText{
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: rotate(-12deg);
  cursor: pointer;
  /* background-color: aqua; */

  /* text-align: center; */
}

.rightText{
 position: absolute;
 overflow: hidden;
 width: 100%;
 height: 50%;
 text-align: center;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 transform: rotate(11deg);
 cursor: pointer;
}

.amount{
    overflow: hidden;
    font-family: JosefinSans-Regular;
    /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
    font-size: 31px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.amount2{
    overflow: hidden;
    font-family: JosefinSans-Regular;
    font-size: 38.4px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.label{
    overflow: hidden;
    font-family: JosefinSans-Regular;
    font-size: 8px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.label2{
    overflow: hidden;
    font-family: JosefinSans-Regular;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

@media screen and (max-width:769px), screen and (max-height: 1030px)
{
    .leftHandle{
        width: auto;
        overflow: hidden;
     }
     .rightHandle{
        width: auto;
        overflow: hidden;
     }
     .leftText{
        width: 100%;
        height: 30%;
        overflow: hidden;
     }
     .amount2{
         font-size: 38.4px;
         overflow: hidden;
     }
     .label2{
         font-size: 21px;
         overflow: hidden;
     }
     .amount{
        font-size: 31px;
        overflow: hidden;
     }
     .label{
      font-size: 4px;
      overflow: hidden;
     }
     .rightText{
        width: 100%;
        overflow: hidden;
        height: 52%;
     }
}

@media screen and (max-width:1050px){
 .leftHandle{
    width: auto;
    overflow: hidden;
 }
 .rightHandle{
    width: auto;
    overflow: hidden;
 }
 .leftText{
    width: 100%;
    overflow: hidden;
    height:30%;
 }
 .amount2{
     font-size: 38.4px;
     overflow: hidden;
 }
 .label2{
     font-size: 21px;
     overflow: hidden;
 }
 .amount{
    font-size: 31px;
    overflow: hidden;
 }
 .label{
  font-size: 5px;
  overflow: hidden;
 }
 .rightText{
    width:100%;
    overflow: hidden;
    height:52%;
 }
}

@media screen and (max-width:770px){
    .leftHandle{
        width: auto;
        overflow: hidden;
     }
     .rightHandle{
        width: auto;
        overflow: hidden;
     }
     .leftText{
        width: 100%;
        overflow: hidden;
        height:30%;
     }
     .amount2{
         font-size: 41.4px;
         overflow: hidden;
     }
     .label2{
         font-size: 25px;
         overflow: hidden;
     }
     .amount{
        font-size: 32px;
        overflow: hidden;
     }
     .label{
      font-size: 5px;
      overflow: hidden;
     }
     .rightText{
        width:100%;
        overflow: hidden;
        height:50%;
     }
}


@media screen and (max-width:500px){
    .topContainer{
            height: 18%;
    }
    .mainContainer{
        width: 100%;
        /* overflow: hidden; */
        /* margin: 0; */
    }
    .leftHandle{
        width: auto;
        overflow: hidden;
     }
     .rightHandle{
        width: auto;
        overflow: hidden;
     }
     .leftText{
        width: 100%;
        overflow: hidden;
        height:30%;
     }
     .amount2{
         font-size: 38.4px;
         overflow: hidden;
     }
     .label2{
         font-size: 22px;
         overflow: hidden;
     }
     .amount{
        font-size: 30px;
        overflow: hidden;
     }
     .label{
      font-size: 5px;
      overflow: hidden;
     }
     .rightText{
        width:100%;
        height:50%;
        overflow: hidden;
     }
}

@media screen and (max-width:370px){
    .topContainer{
        height: 18%;
    }
    .mainContainer{
        width: 100%;
        /* overflow: hidden; */
    }
    .leftHandle{
        width: auto;
        overflow: hidden;
     }
     .rightHandle{
        width: auto;
        overflow: hidden;
     }
     .leftText{
        width: 100%;
        overflow: hidden;
        height:30%;
     }
     .amount2{
         font-size: 38.4px;
         overflow: hidden;
     }
     .label2{
         font-size: 19px;
         overflow: hidden;
     }
     .amount{
        font-size: 29px;
        overflow: hidden;
     }
     .label{
      font-size: 4px;
      overflow: hidden;
     }
     .rightText{
        width:100%;
        height:50%;
        overflow: hidden;
     }
}
