.blurm{
  width: 100vw;
  height: 100vh;
  opacity: 0.75;
  -webkit-filter: blur(13px);
  filter: blur(13px);
  position:absolute;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.check{

}

.triangle{
  width: 320;
  height: 410;
  border-left: 200px solid transparent;
  border-right: 200px solid transparent;
  border-bottom: 400px solid #66b3e3;
  }
  .triangleMM{
    width: 320;
    height: 410;
    border-left: 130px solid transparent;
    border-right: 130px solid transparent;
    border-bottom: 230px solid #e58ebb;
    }
.triangleSM{
  width: 320;
  height: 410;
  border-left: 90px solid transparent;
  border-right: 80px solid transparent;
  border-bottom: 160px solid #66b3e3;
  }

.triangleXS{
  width: 320;
  height: 410;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-bottom: 120px solid #7b76b6;
  }

.halfCircle {
    width: 160px;
    height: 300px;
    background-color: gold;
    border-bottom-right-radius: 160px;
    border-top-right-radius: 160px;
}

.circle {
  width: 250px;
  height: 250px;
  border-radius: 125px;
  background-color: #0097a9;
}

.line {
  width:8px;
  height: 380px;
  background-color: red;
}

.graphics-container {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1.2);
}

.lossHeaderText{
  text-align: start;
  font-family: 'Manrope-SemiBold';
  font-size: 50px;
  font-weight: 600;
     color: #2f2f2f;
    text-transform: uppercase;
}
.lossContentText{
  font-family: 'Manrope-Light';
  font-size: 16px;
  color: #2f2f2f;
  font-style: normal;
  font-weight: 550;
  min-width: 80%;
}

@media screen and (max-width: 320px){
  .graphics-container{
    -webkit-transform: scale(0.5) translate(98px, 0px);
    -ms-transform: scale(0.5) translate(98px, 0px);
    transform: scale(0.5) translate(98px, 0px); 
  }
  .loss-intro-body{
    -webkit-transform: scale(0.98) translate(-198px, -121px);
    -ms-transform: scale(0.98) translate(-18px, -121px);
    transform: scale(0.98) translate(-18px, -121px);
  }

  .r-fontSize-18i18we{ 
    font-size: 43px;

  }
  .lossHeaderText{
  text-align: start;
  font-family: 'Manrope-SemiBold';
  font-size: 42px;
  font-weight: 600;
     color: #2f2f2f;
    text-transform: uppercase;
}
.lossContentText{
  font-family: 'Manrope-Regular';
  font-size: 16px;
  color: #2f2f2f;
  font-style: normal;
}
}
@media screen and (min-width: 320px){
  .graphics-container{
    -webkit-transform: scale(0.5) translate(98px, 0px);
    -ms-transform: scale(0.5) translate(98px, 0px);
    transform: scale(0.5) translate(98px, 0px); 
  }
  .loss-intro-body{
    -webkit-transform: scale(0.98) translate(-18px, -121px);
    -ms-transform: scale(0.98) translate(-18px, -121px);
    transform: scale(0.98) translate(-18px, -121px);
  }

  .r-fontSize-18i18we{ 
    font-size: 43px;

  }
  .lossHeaderText{
    text-align: start;
    font-family: 'Manrope-SemiBold';
    font-size: 42px;
    font-weight: 600;
       color: #2f2f2f;
    text-transform: uppercase;
    margin-top: 50px;
  }
  .lossContentText{
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 400;
    text-align: start;
    margin-top: 15px;
   }
}

@media screen and (min-width: 360px){
  .graphics-container{
    -webkit-transform: scale(0.56) translate(98px, 0px);
    -ms-transform: scale(0.56) translate(98px, 0px);
    transform: scale(0.56) translate(98px, 0px); 
  }
  .loss-intro-body{
    -webkit-transform: scale(0.98) translate(-18px, -121px);
    -ms-transform: scale(0.98) translate(-18px, -121px);
    transform: scale(0.98) translate(-18px, -121px);
  }

  .r-fontSize-18i18we{ 
    font-size: 46px;

  }
  .lossHeaderText{
    text-align: start;
    font-family: 'Manrope-SemiBold';
    font-size: 42px;
    font-weight: 600;
       color: #2f2f2f;
      text-transform: uppercase;
  }
}
@media screen and (min-width: 375px){

  .graphics-container{
    -webkit-transform: scale(0.6) translate(76px, 0px);
    -ms-transform: scale(0.6) translate(76px, 0px);
    transform: scale(0.6) translate(76px, 0px); 
  }
  .loss-intro-body{
    -webkit-transform: scale(0.98) translate(-18px, -105px);
    -ms-transform: scale(0.98) translate(-18px, -105px);
    transform: scale(0.98) translate(-18px, -105px);
  }

  .r-fontSize-18i18we{ 
    font-size: 46px;

  }
  .lossHeaderText{
    text-align: start;
    font-family: 'Manrope-SemiBold';
    font-size: 42px;
    font-weight: 600;
       color: #2f2f2f;
      text-transform: uppercase;
  }
}

@media screen and (min-width: 411px){
  .graphics-container{
    -webkit-transform: scale(0.6) translate(76px, 0px);
    -ms-transform: scale(0.6) translate(76px, 0px);
    transform: scale(0.66) translate(94px, 0px); 
  }
  .loss-intro-body{
    -webkit-transform: scale(0.98) translate(-18px, -105px);
    -ms-transform: scale(0.98) translate(-18px, -105px);
    transform: scale(0.98) translate(-18px, -105px);
  }

  .r-fontSize-18i18we{ 
    font-size: 46px;

  }
  .lossHeaderText{
    text-align: start;
    font-family: 'Manrope-SemiBold';
    font-size: 42px;
    font-weight: 600;
    color: #2f2f2f;
       color: #2f2f2f;
      text-transform: uppercase;
  }
}
@media screen and (min-width: 540px){}
@media screen and (min-width: 768px){
  .graphics-container{
    -webkit-transform: scale(1.07) translate(135px, 0px);
    -ms-transform: scale(1.07) translate(135px, 0px);
    transform: scale(1.07) translate(135px, 0px); 
  }
  .loss-intro-body{
    -webkit-transform: scale(1.2) translate(30px, -120px);
    -ms-transform: scale(1.2) translate(30px, -120px);
    transform: scale(1.2) translate(30px, -120px);
  }

  .r-fontSize-18i18we{ 
    font-size: 46px;

  }

  .r-fontFamily-qbm3s0 {
    font-family: Manrope-Regular;
    width: 48vh;
  }
  .lossHeaderText{
    text-align: start;
    font-family: 'Manrope-SemiBold';
    font-size: 50px;
    font-weight: 600;
       color: #2f2f2f;
      text-transform: uppercase;
  }
  .lossContentText{
    font-size: 14px;
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1024px){

  .graphics-container{
    -webkit-transform: scale(1) translate(110px, 0px);
    -ms-transform: scale(1) translate(110px, 0px);
    transform: scale(1) translate(110px, 0px); 
  }
  .loss-intro-body{
    -webkit-transform: scale(1.2) translate(64px, -120px);
    -ms-transform: scale(1.2) translate(64px, -120px);
    transform: scale(1.2) translate(64px, -120px);
  }

  .r-fontSize-18i18we{ 
    font-size: 62px;

  }

  .r-fontFamily-qbm3s0 {
    font-family: Manrope-Regular;
    width: 39vh;
    font-size: 18px;
  }

  .lossHeaderText{
    text-align: start;
    font-family: 'Manrope-SemiBold';
    font-size: 50px;
    font-weight: 600;
       color: #2f2f2f;
      text-transform: uppercase;
  }
  .lossContentText{
    font-size: 14px;
    min-width: 113%;
  }

  
}
@media screen and (min-width: 1280px){}
@media screen and (min-width: 1366px){

  .graphics-container{
    -webkit-transform: scale(1) translate(30px, 0px);
    -ms-transform: scale(1) translate(30px, 0px);
    transform: scale(1) translate(30px, 0px); 
  }
  .loss-intro-body{
    -webkit-transform: scale(1.2) translate(64px, 0px);
    -ms-transform: scale(1.2) translate(64px, 0px);
    transform: scale(1.2) translate(64px, 0px);
  }

  .r-fontSize-18i18we{ 
    font-size: 60px;

  }

  .r-fontFamily-qbm3s0 {
    font-family: Manrope-Regular;
    width: 70vh;
    font-size: 18px;
  }
  .lossHeaderText{
    text-align: start;
    font-family: 'Manrope-SemiBold';
    font-size: 50px;
    font-weight: 600;
       color: #2f2f2f;
      text-transform: uppercase;
  }
  .lossContentText{
    font-size: 15px;
  }
}
/* @media screen and (min-width: 1440px){

  .graphics-container{
    -webkit-transform: scale(1.2) translate(30px, 0px);
    -ms-transform: scale(1.2) translate(30px, 0px);
    transform: scale(1.2) translate(30px, 0px); 
  }
  .loss-intro-body{
    -webkit-transform: scale(1.1) translate(64px, -100px);
    -ms-transform: scale(1.1) translate(64px, -100px);
    transform: scale(1.1) translate(64px, -100px);
  }

  .r-fontSize-18i18we{ 
    font-size: 60px;

  }

  .r-fontFamily-qbm3s0 {
    font-family: Manrope-Regular;
    width: 70vh;
    font-size: 18px;
  }

} */


/* @media screen and (max-width: 479px) {
  .graphics-container{
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  }
  .loss-intro-body{
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8) translate(0px, -85px);
  }
} */
 