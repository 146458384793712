.Halfcircle{
   /* mix-blend-mode: overlay; */
        position: absolute;
            width: 130px;
            height: 238px;
            background-color: #db7d72;
            border-bottom-left-radius: 160px;
            border-top-left-radius: 160px;
        
}
.rectangle{
    /* mix-blend-mode: overlay; */
    position: absolute;
    /* bottom: 10; */
     height: 224;
      width: 224;
      background-color: '#7ebd5f';
        /* right: 350 */
}
.triangle1{
    width: 365;
      height: 336;
      border-left: 175px solid transparent;
      border-right: 175px solid transparent;
      border-bottom: 320px solid #db7d72;
  }

  .mobileresponsive{
    background-color:'';
      }
       
      
      @media screen and (max-width:350px) {
        .mobileresponsive{
          -webkit-transform: scale(0.38);
          -ms-transform: scale(0.38);
          transform: scale(0.38);
        }
    }
    @media screen and (min-width:350px) {
        .mobileresponsive{
          -webkit-transform: scale(0.45);
          -ms-transform: scale(0.45);
          transform: scale(0.45);
        }
    }
    @media screen and (min-width:768px) {
      .mobileresponsive{
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
      }
  }
    @media screen and (min-width:1000px) {
        .mobileresponsive{
          -webkit-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1);
        }
    } 
    @media screen and (min-width:1025px) {
      .mobileresponsive{
        -webkit-transform: scale(0.95);
        -ms-transform: scale(0.95);
        transform: scale(0.95);
      }
  } 