.triangle{
    width: 323;
      height: 413;
      border-left: 200px solid transparent;
      border-right: 200px solid transparent;
      border-bottom: 400px solid #66b3e3;
  }
  .trianglesmall{
    width: 173;
      height: 146;
      border-left: 73px solid transparent;
      border-right: 73px solid transparent;
      border-bottom: 146px solid #66b3e3;
  }
  .rectangle{
      /* mix-blend-mode: overlay; */
      position: absolute;
      bottom: 10;
       height: 224;
        width: 224;
        background-color: '#7ebd5f';
          right: 350
  }
  .mobileresponsive{
background-color:'';
  }
   
   @media screen and (min-width:768px) {
      .mobileresponsive{
        -webkit-transform: scale(0.6);
        -ms-transform: scale(0.6);
        transform: scale(0.6);
      }
  }
  @media screen and (max-width:350px) {
    .mobileresponsive{
      -webkit-transform: scale(0.38);
      -ms-transform: scale(0.38);
      transform: scale(0.38);
    }
}
@media screen and (min-width:350px) {
    .mobileresponsive{
      -webkit-transform: scale(0.45);
      -ms-transform: scale(0.45);
      transform: scale(0.45);
    }
}
@media screen and (min-width:1000px) {
    .mobileresponsive{
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
} 