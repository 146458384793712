
*{
  -webkit-margin-start : (left);
-webkit-margin-end :(right);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'JosefinSans-Regular';
  src: local('JosefinSans-Regular'), url(./assets/fonts/JosefinSans-Regular.ttf) format('truetype');
  }
@font-face {
  font-family: 'JosefinSans-Bold';
  src: local('JosefinSans-Bold'), url(./assets/fonts/JosefinSans-Bold.ttf) format('truetype');
  }
@font-face {
  font-family: 'JosefinSans-Light';
  src: local('JosefinSans-Light'), url(./assets/fonts/JosefinSans-Light.ttf) format('truetype');
  }
@font-face {
  font-family: 'JosefinSans-Thin';
  src: local('JosefinSans-Thin'), url(./assets/fonts/JosefinSans-Thin.ttf) format('truetype');
  }
@font-face {
  font-family: 'JosefinSans-SemiBold';
  src: local('JosefinSans-SemiBold'), url(./assets/fonts/JosefinSans-SemiBold.ttf) format('truetype');
  }
@font-face {
  font-family: 'OpenSans-Bold';
  src: local('OpenSans-Bold'), url(./assets/fonts/OpenSans-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'OpenSans-Regular';
  src: local('OpenSans-Regular'), url(./assets/fonts/OpenSans-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'OpenSans-SemiBold';
  src: local('OpenSans-SemiBold'), url(./assets/fonts/OpenSans-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'OpenSans-Light';
  src: local('OpenSans-Light'), url(./assets/fonts/OpenSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Manrope-Bold';
  src: local('Manrope-Bold'), url(./assets/fonts/Manrope-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope-ExtraBold';
  src: local('Manrope-ExtraBold'), url(./assets/fonts/Manrope-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope-ExtraLight';
  src: local('Manrope-ExtraLight'), url(./assets/fonts/Manrope-ExtraLight.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope-Light';
  src: local('Manrope-Light'), url(./assets/fonts/Manrope-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope-Medium';
  src: local('Manrope-Medium'), url(./assets/fonts/Manrope-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope-Regular';
  src: local('Manrope-Regular'), url(./assets/fonts/Manrope-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope-Regulars';
  src: local('Manrope-Regulars'), url(./assets/fonts/Manrope-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope-SemiBold';
  src: local('Manrope-SemiBold'), url(./assets/fonts/Manrope-SemiBold.ttf) format('truetype');
}

.triangle{
  width: 323;
  height: 413;
  border-left: 200px solid transparent;
  border-right: 200px solid transparent;
  border-bottom: 400px solid #66b3e3;
  }

  .navigation-panel{
    width: 58%;
    /* width: (actualWidth < 1300) ? (actualWidth < 900 ? (actualWidth < 600) ? "98%" : "95%" : "80%") : "58%"; */
    align-self: center;
    height: 45%;
    justify-content:space-evenly;
    align-items: center;
  }

  .menu-items{
    text-align: center;
        font-family: Manrope-SemiBold;
        font-size:16;
        font-weight:600;
        color: '#2f2f2f';
        line-height: 24;
  }

  @media screen and (max-width:1200px) {
    .navigation-panel{
      width: 80%;
      /* width: (actualWidth < 1300) ? (actualWidth < 900 ? (actualWidth < 600) ? "98%" : "95%" : "80%") : "58%"; */
      align-self: center;
      height: 45%;
      justify-content:space-evenly;
      align-items: center;
    }

    .menu-items{
      text-align: center;
      font-size:14;
    }
}

@media screen and (max-width:900px) {
  .navigation-panel{
    width: 95%;
    /* width: (actualWidth < 1300) ? (actualWidth < 900 ? (actualWidth < 600) ? "98%" : "95%" : "80%") : "58%"; */
    align-self: center;
    height: 45%;
    justify-content:space-evenly;
    align-items: center;
  }
}

@media screen and (max-width:600px) {
  .navigation-panel{
    width: 98%;
    /* width: (actualWidth < 1300) ? (actualWidth < 900 ? (actualWidth < 600) ? "98%" : "95%" : "80%") : "58%"; */
    align-self: center;
    height: 45%;
    justify-content:space-evenly;
    align-items: center;
  }
}

  /* @media */

  body{
    background: #eee;
    }
    .spinner {
        width: 70px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -11px 0 0 -35px;
    }

    .spinner > div {
      width: 18px;
      height: 18px;
      background-color: #575F69;

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .spinner .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .spinner .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {
      0%, 80%, 100% { -webkit-transform: scale(0) }
      40% { -webkit-transform: scale(1.0) }
    }

    @keyframes sk-bouncedelay {
      0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
      } 40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
      }
    }



/* loader css */

body{
  background: #eee;

  overflow: hidden;
/* background-color: red; */
}
.spineerOne {
width: 70px;
text-align: center;
position: absolute;
top: 62%;
left: 50%;
margin: -11px 0 0 -35px;
}

.spineerOne > div {
width: 18px;
height: 18px;
background-color: #575F69;

border-radius: 100%;
display: inline-block;
-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spineerOne .bounce1 {
-webkit-animation-delay: -0.32s;
animation-delay: -0.32s;
}

.spineerOne .bounce2 {
-webkit-animation-delay: -0.16s;
animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
0%, 80%, 100% { -webkit-transform: scale(0) }
40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
0%, 80%, 100% {
-webkit-transform: scale(0);
transform: scale(0);
} 40% {
-webkit-transform: scale(1.0);
transform: scale(1.0);
}
}

.loaderContainer{
height: auto;
position: absolute;
left:50%;
top:33%;
transform: translateX(-50%);
width:300px;
display: flex;
flex-direction: column;
align-items: center;
/*justify-content: */
}

.lining1{
height: 1px;
width:222px;
background-color: #575f69;
}

.lining2{
height: 1px;
width:222px;
background-color: #575f69;
}

.loaderText{
  width: 300px;
  /* height: 40px; */
  margin-top: 14px;
  margin-bottom: 8px;
  text-transform: uppercase;
  /* font-size: 25px; */
  color: #575f69;
  text-align: center;
  font-family: 'JosefinSans-Light';
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 1.2px;
}

.firstCardShadow {
  width: 192px;
  height: 29px;
  opacity: 0.1;
  align-self: center;
  mix-blend-mode: multiply;
  background-image: radial-gradient(circle at 50% 50%, #000000, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0) 15%);
}

.secondCardShadow {
  width: 192px;
  height: 29px;
  opacity: 0.07;
  align-self: center;
  mix-blend-mode: multiply;
  background-image: radial-gradient(circle at 50% 50%, #000000, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0) 15%);
}

.firstCardShadow1{
  width: 159px;
height: 24px;
opacity: 0.1;
align-self: center;
mix-blend-mode: multiply;
background-image: radial-gradient(circle at 50% 50%, #000000, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0) 15%);
}

.secondCardShadow1{
  width: 159px;
height: 24px;
opacity: 0.07;
align-self: center;
mix-blend-mode: multiply;
background-image: radial-gradient(circle at 50% 50%, #000000, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0) 15%);
}

.firstCardShadow2{
  width: 123px;
  height: 19px;
  opacity: 0.1;
  align-self: center;
  mix-blend-mode: multiply;
  background-image: radial-gradient(circle at 50% 50%, #000000, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0) 15%);
}

.secondCardShadow2{
  width: 123px;
height: 19px;
opacity: 0.07;
align-self: center;
mix-blend-mode: multiply;
background-image: radial-gradient(circle at 50% 50%, #000000, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0) 15%);
}
.triangle{
  width: 323;
	height: 413;
	border-left: 200px solid transparent;
	border-right: 200px solid transparent;
	border-bottom: 400px solid #66b3e3;
}
/* @media screen and (max-width:405px){


}

@media screen and (max-width:px){



} */
.segment-group:hover .wheel-segment {
  fill: #926500;
  cursor: pointer;
}

.segment-group.selected .wheel-segment,
.segment-group.single.selected ~ .segment-group .wheel-segment {
  fill: #1BB3BC !important;
  transition: fill .3s ease;
  color: white;
}

.segment-group.single.selected:hover .wheel-segment{
  fill: #926500;
}

.segment-group.selected:hover .wheel-segment, .segment-group.single.selected:hover .wheel-segment{
  fill: #926500;
}


.segment-group.selected .wheel-segment-text tspan{
  fill: #fff;
  transition: fill .3s ease;
}

.button-container {
  position: relative;
}

.button.experience {
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  border: 1px solid #000000b0;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 19px;
  line-height: 46px;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 28%;
  color: #000000b0;
  display: block;
}
.center-text-for-chart{
  left: 53%;
  transform: translateX(-50%);
  cursor: pointer;
  padding-left: 30px;
  padding-right: 40px;
  font-size: 16.5px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 44%;
  color: #000000b0;
}

.segment-group .wheel-segment-text tspan {
  fill: #FFE696;
  cursor: pointer;
}

@keyframes flip {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}
.flip-element {
  animation: flip 0.5s ease-out 0s 1 normal forwards;
  transform-origin: center; /* Adjust as needed */
  z-index: 99;
}

.hoverable-path {
  fill: #a27000;
  padding: 10px;
  stroke: transparent;
  stroke-width: 1;
  cursor: pointer !important;
}
.hoverable-path.selected{
  fill: #1BB3BC !important
}

.hoverable-path:hover {
  fill: #926500;
  cursor: pointer !important;
}

.segment-group .wheel-segment {
  stroke: #FFCE0B;
}

.tiger-first-page-component-circle{
  position: absolute !important;
  z-index: 99 !important;
}
