@font-face {
    font-family: 'Manrope-Bold';
    src: local('Manrope-Bold'), url(../../../../../assets/fonts/Manrope-Bold.ttf) format('truetype');
  } 
  @font-face {
    font-family: 'Manrope-ExtraBold';
    src: local('Manrope-ExtraBold'), url(../../../../../assets/fonts/Manrope-ExtraBold.ttf) format('truetype');
  } 
  @font-face {
    font-family: 'Manrope-ExtraLight';
    src: local('Manrope-ExtraLight'), url(../../../../../assets/fonts/Manrope-ExtraLight.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Manrope-Light';
    src: local('Manrope-Light'), url(../../../../../assets/fonts/Manrope-Light.ttf) format('truetype');
  } 
  @font-face {
    font-family: 'Manrope-Medium';
    src: local('Manrope-Medium'), url(../../../../../assets/fonts/Manrope-Medium.ttf) format('truetype');
  } 
  @font-face {
    font-family: 'Manrope-Regulars';
    src: local('Manrope-Regulars'), url(../../../../../assets/fonts/Manrope-Regular.ttf) format('truetype');
  } 
  @font-face {
    font-family: 'Manrope-SemiBold';
    src: local('Manrope-SemiBold'), url(../../../../../assets/fonts/Manrope-SemiBold.ttf) format('truetype');
  } 


